import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import { ConnectModules } from "../utils/ConnectModules"
import { PreviewContext } from "../context/PreviewContext"
import { useLiveQuery } from "@sanity/preview-kit"
import { productPageQuery } from "../queries/documentQueries"

const getDefaultSelectedVariant = (sanityData, UTMParameterArray) => {
  if (UTMParameterArray.some(el => el.includes("variant="))) {
    const utmVariantString = UTMParameterArray.find(el =>
      el.includes("variant=")
    )
    const utmVariantId = utmVariantString?.split("=")[1]
    const utmVariant = (
      sanityData?.store?.variants || sanityData.sanityProduct.store?.variants
    ).find(variant => variant.store.id.toString() === utmVariantId)
    if (utmVariant) {
      return utmVariant
    } else {
      return (
        sanityData.initialVariant ||
        sanityData.sanityProduct.store?.variants[0] ||
        sanityData?.store?.variants[0]
      )
    }
  } else {
    return (
      sanityData.initialVariant ||
      sanityData.sanityProduct.store?.variants[0] ||
      sanityData?.store?.variants[0]
    )
  }
}

const ProductPage = props => {
  const { data } = props
  const [showLazySections, setShowLazySection] = useState(false)
  const {
    previewActive,
    setPreviewActive,
    setPreviewContextData,
    setPreviewIsLoading,
    setPreviewValidationData,
    setIsNewUnpublishedDoc,
    isNewUnpublishedDoc
  } = useContext(PreviewContext)

  const location = useLocation()
  const [pageModules, setPageModules] = useState(
    data.sanityProduct.content?.main?.modules
  )

  const [previewData, sanityPreviewIsLoading] = useLiveQuery(
    null,
    productPageQuery,
    {
      slug: props.pageContext.productSlug
    }
  )

  useEffect(() => {
    setPreviewIsLoading(sanityPreviewIsLoading)
  }, [sanityPreviewIsLoading])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const previewModeParameter = urlSearchParams.get("previewMode")
    const previewDatasetParameter = urlSearchParams.get("previewDataset")
    const previewValidationDataParameter = urlSearchParams.get("validation")
    const previewIsNewUnpublishedDocParameter =
      urlSearchParams.get("isNewUnpublishedDoc") === "true"

    if (previewValidationDataParameter) {
      setPreviewValidationData(JSON.parse(previewValidationDataParameter))
    }

    if (previewModeParameter) {
      setPreviewActive(true)
    }
    if (previewDatasetParameter) {
      setPreviewContextData({ previewDataset: previewDatasetParameter })
    }

    if (previewIsNewUnpublishedDocParameter) {
      setIsNewUnpublishedDoc(previewIsNewUnpublishedDocParameter)
    }
  }, [])

  const productStore = previewData?.store || data.sanityProduct.store
  const baseModules =
    previewData?.content?.main?.modules ||
    data.sanityProduct.content?.main?.modules
  const UTMParameterArray = location.search.substring(1).split("&")

  const [selectedVariant, setSelectedVariant] = useState(
    getDefaultSelectedVariant(data, UTMParameterArray)
  )
  // The state won't be changed later because is only there for tracking on what is the initial variant on first mount
  const [
    selectedVariantAfterFirstPageLoad,
    setSelectedVariantAfterFirstPageLoad
  ] = useState(getDefaultSelectedVariant(data, UTMParameterArray))
  const initialVariant =
    previewData?.initialVariant || data.sanityProduct.initialVariant

  useEffect(() => {
    setShowLazySection(true)
  }, [])

  useEffect(() => {
    const selectedVariantModules = selectedVariant?.modules
    if (selectedVariantModules) {
      setPageModules(
        baseModules?.map(module => {
          const moduleShouldNotBeOverridden =
            module?.disableModule || !module?.overrideID
          return moduleShouldNotBeOverridden
            ? module
            : selectedVariantModules.find(
                variantModule =>
                  variantModule?.overrideID === module?.overrideID &&
                  !variantModule?.disableModule
              )
        })
      )
    } else {
      setPageModules(baseModules)
    }
  }, [selectedVariant, baseModules])

  const metaInfo = data.sanityProduct.content?.meta

  if (sanityPreviewIsLoading) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      <Helmet>
        <body className="root" />
      </Helmet>
      <Layout>
        <div id="top" className="max-w-full">
          <Seo currentResourceMetaInformation={metaInfo} pagePath={location} />
          <div className="w-full">
            {ConnectModules(
              pageModules,
              productStore,
              selectedVariant,
              setSelectedVariant,
              initialVariant,
              selectedVariantAfterFirstPageLoad
            )}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ProductPage

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    sanityProduct(id: { eq: $id }) {
      content {
        main {
          modules {
            ...moduleProductInterfaceQuery
            ...moduleReviewsQuery
            ...moduleCustomerImagesQuery
            ...moduleAboutArtistQuery
            ...moduleContentBoxQuery
            ...moduleFollowUsQuery
            ...moduleRelatedProductsQuery
            ...moduleTikTokEmbedQuery
            ...moduleYoutubeEmbedQuery
            ...moduleTextAndCarouselQuery
            ...modulePortraitQuery
            ...moduleHabitatSectionQuery
            ...moduleInfluenceSectionQuery
            ...moduleCertificationsSectionQuery
            ...moduleClimatePositiveSectionQuery
            ...moduleProductionSectionQuery
            ...moduleBlockContentQuery
            ...moduleModalQuery
            ...moduleFAQsectionQuery
            ...moduleVideoBlockQuery
          }
        }
        meta {
          _key
          metaImage {
            asset {
              url
            }
          }
          metaKeywords
          metaTitle
          metaDescription
          openImage {
            asset {
              url
            }
          }
          openTitle
          openGraphDescription
          twitterImage {
            asset {
              url
            }
          }
        }
      }
      initialVariant {
        _id
        gtin
        allowPurchase
        ATCButtonText
        storeFinderEnabled
        indicatorText
        indicatorColor {
          oneOffColor {
            hex
          }
          designSystemColor {
            color {
              hex
            }
          }
        }
        images {
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
          ... on SanityVideo {
            _key
            _type
            playBackId
          }
        }
        variantPreviewImage {
          asset {
            url
          }
        }
        variantPreviewColor {
          ... on SanityColorChoice {
            oneOffColor {
              hex
            }
            designSystemColor {
              color {
                hex
              }
            }
          }
        }
        modules {
          ...moduleAboutArtistQuery
        }
        store {
          id
          productId
          previewImageUrl
          sku
          title
          price
          inventory {
            management
            policy
            quantity
          }
        }
      }
      store {
        _key
        title
        id
        gid
        previewImageUrl
        slug {
          current
        }
        variants {
          _id
          gtin
          allowPurchase
          ATCButtonText
          storeFinderEnabled
          indicatorText
          indicatorColor {
            oneOffColor {
              hex
            }
            designSystemColor {
              color {
                hex
              }
            }
          }
          images {
            ... on SanityImage {
              _key
              _type
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            ... on SanityVideo {
              _key
              _type
              playBackId
            }
          }
          variantPreviewImage {
            asset {
              url
            }
          }
          variantPreviewColor {
            ... on SanityColorChoice {
              oneOffColor {
                hex
              }
              designSystemColor {
                color {
                  hex
                }
              }
            }
          }
          modules {
            ...moduleAboutArtistQuery
          }
          store {
            id
            productId
            previewImageUrl
            sku
            title
            price
            inventory {
              management
              policy
              quantity
            }
          }
        }
      }
    }
  }
`
